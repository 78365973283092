$(".spoiler-card__show").click(function() {
    $(this).parents(".spoiler-card").toggleClass("_active")
    $(this).siblings(".spoiler-card__hidden").slideToggle()
})

$("._tab").click(function() {
    let parentBlock = $(this).parents(".tabs-parent")
    let tabId = $(this).attr("data-tab")
    $(parentBlock).find("._tab").removeClass("_active")
    $(this).addClass("_active")
    $(parentBlock).find("._tab-content").removeClass("_active")
    $(parentBlock).find(`._tab-${tabId}`).addClass("_active")
})

$(".quiz-answer__close").click(function() {
    let parentBlock = $(this).parents(".tabs-parent")
    $(parentBlock).find("._tab").removeClass("_active")
    $(parentBlock).find("._tab-content").removeClass("_active")
})

$(".pass-toggle").click(function() {
    let inputType = 'password'
    if ($(this).hasClass("_active")) {
        $(this).removeClass("_active")
        inputType = 'password'
    } else {
        $(this).addClass("_active")
        inputType = 'text'
    }
    $(this).siblings("input").attr("type", inputType)
})

function cartCalc() {
    $('.cartcalc .ccalc-minus').click(function() {
        let a = $(this).closest('.cartcalc').find('input').val();
        if (a > 1) {
            let b = +a - 1;
            $(this).closest('.cartcalc').find('input').val(b);
        } else {
            $(this).closest('.cartcalc').find('input').val(a);
            $(this).parents(".cartcalc").siblings("._add-cart").removeClass("_added")
        }
        if (a == 2) {
            if (!$(this).parents(".cartcalc").hasClass("min0")) {
                $(this).prop("disabled", true)
            }

        }
    });
    $('.cartcalc .ccalc-plus').click(function() {
        let a = $(this).closest('.cartcalc').find('input').val();
        let b = +a + 1;
        $(this).closest('.cartcalc').find('input').val(b);
        $(this).siblings('.ccalc-minus').prop("disabled", false)
    });

    $(".cartcalc:not(.min0) input").each(function(index, el) {
        let val = $(el).val()
        if (val == '1') {
            $(this).siblings('.ccalc-minus').prop("disabled", true)
        } else {
            $(this).siblings('.ccalc-minus').prop("disabled", false)
        }
    });
}

cartCalc()