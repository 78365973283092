if(window.innerWidth>992){
    $(".menu__item-show").mouseover(function () {
        console.log(true)
        $(this).parents(".menu__item").siblings(".menu__item").removeClass("_active")
        $(this).parents(".menu__item").addClass("_active")
    })


    $(".header-bottom").mouseleave(function () {
        $(".menu__item").removeClass("_active")
    })
}else{
    $(".menu__item-show").click(function () {
        $(this).parents(".menu__item").toggleClass("_active")
        $(this).siblings(".menu__item-hidden").slideToggle()
    })
}


$(".burger").click(function () {
    $(".header").toggleClass("_open")
    $("body").toggleClass("fixed-body")
})

$("._toggle-search").click(function () {
    $(".header").toggleClass("_open-search")
})
