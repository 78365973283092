$(document).ready(function() {
    $('input[type="tel"]').mask('+7 999 999-9999', { autoclear: false }, { placeholder: '+7             ' });
    $('.codeField input').mask('9 9 9 9', { autoclear: false }, { placeholder: '       ' });


    $(".m-select select").niceSelect()



});


$("._show-product").click(function () {
    showProduct()
})

function showProduct() {
    $("._show-product").addClass("_disabled")
    $(".cart-modal").addClass("_active")

    $(document).mouseup(function(e) {
        var div = $('.cart-modal');
        if (!div.is(e.target) && div.has(e.target).length === 0) {
            if($(div).hasClass("_active")){
                $("._show-product").removeClass("_disabled")
                $(div).removeClass("_active")
            }
        }
    });
}
