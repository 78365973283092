$(".models__links").each(function (index, el){
    let linksCount = $(el).find(".model-link").length
    if(linksCount > 22){
        $(el).addClass("_cut")
    }
});

$(".models__links._cut ~.models__more").click(function () {
    $(this).siblings(".models__links").removeClass("_cut")
})

$(".m-table__sort").click(function () {
    let table = $(this).parents("table")
    $(this).toggleClass("_active")
})

$("._add-favourite").click(function () {
    $(this).toggleClass("_added")
})

$("._add-cart").click(function () {
    $(this).toggleClass("_added")
})


$(".filter2__checks").each(function (index, el){
    let checksCount = $(el).find(".m-check").length
    console.log(checksCount)
    if(checksCount > 8){
        $(el).addClass("_more")
    }
});

$(".filter2__checks-more").click(function () {
    $(this).parents(".filter2__checks").toggleClass("_show")
})

$(".catalog__filter-toggle").click(function () {
    $(".filter2").addClass("_active")
    $("body").addClass("fixed-body")
})

$("._close-filter").click(function () {
    $(".filter2").removeClass("_active")
    $("body").removeClass("fixed-body")
})

const rangeSlider = document.querySelector(`.range-slider`)
const rangeInput0 = document.querySelector(`.range-slider__input1`)
const rangeInput1 = document.querySelector(`.range-slider__input2`)
const rangeInputs = [rangeInput0, rangeInput1]
if (rangeSlider) {
    noUiSlider.create(rangeSlider, {
        start: [20000, 80000],
        connect: true,
        step: 1,
        range: {
            'min': 0,
            'max': 100000
        }
    });
}
if (rangeSlider) {
    rangeSlider.noUiSlider.on('update', function(values, handle) {
        rangeInputs[handle].value = Math.round(values[handle])
    })
    rangeInputs.forEach((el, index) => {
        el.addEventListener('change', (e) => {
            setRangeSlider(index, e.currentTarget.value)
        })
    })
}


const setRangeSlider = (i, value) => {
    let array = [null, null];
    array[i] = value;

    rangeSlider.noUiSlider.set(array);
};
