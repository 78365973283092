function mainCollectionSlider() {
    var swiper = new Swiper('.main-collection .swiper-container', {
        slidesPerView: "auto",
        spaceBetween: 0,
        navigation: {
            nextEl: '.main-collection .swiper-button-next',
            prevEl: '.main-collection .swiper-button-prev',
        },
    })
}

function workerSlider() {
    var swiper = new Swiper('.worker .swiper-container', {
        slidesPerView: "auto",
        spaceBetween: 0,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        pagination: {
            el: '.worker .swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
    })
}

let productGalleryArray = []

function productGallery() {
    $(".product-gallery").each(function(index, el) {
        $(el).attr("data-slider-id", `${index}`)
        productGalleryArray[index] = {
            swiper: {},
            swiper2: {}
        }
        productGalleryArray[index] = new Swiper(`.slider[data-slider-id="${index}"] .swiper-container`, {

        })
        productGalleryArray[index].swiper = new Swiper(`.product-gallery[data-slider-id="${index}"] .mySwiper`, {
            spaceBetween: 0,
            slidesPerView: "auto",
            freeMode: true,
            watchSlidesProgress: true,
            direction: 'vertical',
            breakpoints:{
                320: {
                    direction: 'horizontal',
                },
                577: {
                    direction: 'vertical',
                }
            }
        });
        productGalleryArray[index].swiper2 = new Swiper(`.product-gallery[data-slider-id="${index}"] .mySwiper2`, {
            spaceBetween: 0,
            direction: 'vertical',
            thumbs: {
                swiper: productGalleryArray[index].swiper,
            },
            breakpoints:{
                320: {
                    direction: 'horizontal',
                },
                577: {
                    direction: 'vertical',
                }
            }
        });
    })
}



mainCollectionSlider()
workerSlider()
productGallery()
