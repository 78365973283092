$(".cart-table__check-all input").change(function () {
    let checked = $(this).prop("checked")
    $(".cart-table__check-all input").removeClass("_semi-check")

    $(".cart-table__check input").each(function (index, el){
        $(el).prop("checked",checked)
    });

})

$(".cart-table__check input").change(function () {
    let cartItemCount = $(".cart-table__check input").length
    let cartSelectedCount = checkCheckedCount()


    $(".cart-table__check-all input").prop("checked",false)
    if(cartSelectedCount < cartItemCount){
        $(".cart-table__check-all input").addClass("_semi-check")
    }
    if(cartSelectedCount === 0){
        $(".cart-table__check-all input").removeClass("_semi-check")
    }
    if(cartSelectedCount === cartItemCount){
        $(".cart-table__check-all input").removeClass("_semi-check")
        $(".cart-table__check-all input").prop("checked",true)
    }
})

function checkCheckedCount() {
    return $(".cart-table__check input:checked").length
}
